import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { VehicleMutationTypes } from '@/core/enums/Vehicles/Mutations';
import { VehicleActionTypes } from '@/core/enums/Vehicles/Actions';
import { ManufaturersActionTypes } from '@/core/enums/Manufacturers/Actions';
import { ModelsActionTypes } from '@/core/enums/Models/Actions';
import { LinkTypes } from '@/core/enums/Links';
import ApiService from '@/core/services/ApiService';
import Qoute from '@/core/services/car/Qoutes';
import moment from 'moment';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [VehicleActionTypes.GET_VEHICLE](
    { commit }: AugmentedActionContext,
  ): Promise<boolean>;

  [VehicleActionTypes.POST_VEHICLE_LEAD](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [VehicleActionTypes.GET_VEHICLE]({ commit }) {
    return new Promise(() => {
        const vehicle = {
          car_year: new Date().getFullYear(),
          is_new: 2,
          manufacturer_id: null,
          model_id: null,
          trim_level_id: null,
          registration_emirate: 7,
          phone_number: null,
          email: null,
          name: null,
          phone_country_code: '+961',
          car_values: 0,
          is_car: 1,
          lead_source: 'website',
          btm_source: '',
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
          utm_content: '',
          sid: '',
          customer_source_url: '',
          is_wizard: true,
          current_policy_active: 1,
          existing_policy_expired: 2,
          dob: '',
          dob_month: null,
          dob_day: null,
          dob_year: null,
          first_registration_date: null,
          nationality: null,
          first_driving_license_country: null,
          driving_experience: 1,
          uae_driving_experience: 1,
          policy_start_date: null,
          claims: 0,
          claimed_insurance: 2,
          no_claim_certificate: 1,
          gcc_specification: 1,
          personal_use: 1,
          fully_comprehensive: 1,
          third_party_liability: 2,
          gender: 1,
          vehicle_type: 1,
          utmnew:''
        };
        commit(VehicleMutationTypes.SET_VEHICLE, vehicle);
        return true;

    });
  },

  async [VehicleActionTypes.POST_VEHICLE_LEAD](context, payload: any) {
    const source = window.localStorage.getItem('sources')
    const renewal = window.localStorage.getItem('renewal')
    payload.preferred_language = window.localStorage.getItem('language')
    if(renewal === '1')
    {
       // The lead is renewal lead
       payload.renewal = 1;
       window.localStorage.removeItem('renewal') // to avoid logical issues in sessions
    }
    context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, true);
    try {
      const data = await ApiService.post(LinkTypes.POST_VEHICLE_LEAD, payload);
      const uuid = data.data.data.data.uuid.toString().replace('null', '')
      const cuuid = data.data.data.data.cuuid.toString().replace('null', '')
      if(context.rootState.drivers.driver != undefined) {
        context.rootState.drivers.driver.uuid = uuid
        context.rootState.drivers.driver.car_value = payload.car_values
        context.rootState.drivers.driver.is_new = payload.is_new
      }
      window.localStorage.setItem('fall_back', uuid);
      const fall = window.localStorage.getItem('fall')
      //payload.customer_source_url = "/vehicle-details";
      payload.customer_source_url = payload.customer_source_url.replace(fall, cuuid)
      window.localStorage.setItem('fall', cuuid);
      window.localStorage.setItem('customer_detatils', JSON.stringify(payload));
      context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, false);
      const host = (process.env.VUE_APP_FOLDER !== '') ? `${window.location.host}/${process.env.VUE_APP_FOLDER}` : '';
      // let link = `${host}/result-listing?uuid=${uuid}`; // SIT
      // let link = `/${process.env.VUE_APP_FOLDER}/result-listing?uuid=${uuid}`; // UAT
      
      let link = (process.env.NODE_ENV === 'uat')
                      ? `/${process.env.VUE_APP_FOLDER}/result-listing?uuid=${uuid}`
                      : `${host}/result-listing?uuid=${uuid}`;

      if(data.status === 200) {
        const result = await Qoute.sendCheapestQuotes({
          uuid:uuid,
          lang: window.localStorage.getItem('language')
        });
        if(source) link = `${link}${source}`
        if(data.data.data.data.go_back_wizard) {
          const customer = JSON.parse(window.localStorage.getItem('customer_detatils') || '')
          customer.sid = data.data.data.data.sid
          window.localStorage.setItem('customer_detatils', JSON.stringify(customer))
          link = `${link}&sid=${data.data.data.data.sid}`
        }

        window.location.href = link
      }
      // router.push({name: 'DriverDetails', query: {uuid: data.data.data.data.uuid, sid: payload.sid}})
      return data
    } catch (err) {
      console.log(err)
      return err;
    }
  },

  async [VehicleActionTypes.GET_CUSTOMER](context, payload: any) {
    try {
      const { data } = await ApiService.post(LinkTypes.GET_CUSTOMER, payload);
      // console.log({payload})
      window.localStorage.setItem('fall', payload.uuid);
      const result = data.data.customer;
      // console.log('data',data)
      const customer_details = data.data.customer;
      const driver_details = data.data.driver_details;
      
      if(result) {
        const phone_number = result.phone_number_without_code;
        result.phone_number = phone_number;
        result.phone_country_code = result.phone_country_code;
        result.car_year = (result.car_year != undefined) ? result.car_year :new Date().getFullYear();
        result.is_new = 2;
        result.is_car = 1;
        result.manufacturer_id = (result.manufacturer_id != undefined) ? result.manufacturer_id : -1;
        result.model_id = (result.model_id != undefined) ? result.model_id : -1;
        result.trim_level_id = (result.trim_level_id != undefined) ? result.trim_level_id : -1;
        result.registration_emirate = 7;
        result.email = result.email;
        result.car_values = driver_details ? driver_details.car_value : 0;
        result.lead_source = 'website';
        result.dob = payload.vehicle_type == 1 ? (customer_details.latest_car_lead?.lead_driver_detail.dob ?? null) : null;
        
        // result.latest_bike_lead = result.latest_bike_lead;
        // result.bike_year = new Date().getFullYear();
        result.engine_capacity = (result.trim_level_id != undefined) ? result.trim_level_id : -1;
        result.nationality = (result.nationality != undefined) ? result.nationality : null;

        
        

        delete result.id;
        delete result.created_at;
        delete result.current_visited_at;
        delete result.current_visited_date_format;
        delete result.joining_date;
        delete result.last_visited_at;
        delete result.last_visited_date_format;
        delete result.updated_at;
        delete result.latest_car_lead_task;
        delete result.is_health;
        delete result.deleted_at;
        delete result.deleted_by;
        delete result.hot_lead;
        delete result.hot_renewal_lead;
        delete result.invoice_increment_no;
        delete result.agent;
        delete result.class_text;
        delete result.created_by;
        delete result.customer_origin_device;
        delete result.customer_origin_lang;
        delete result.customer_source;
        delete result.customer_status_id;
        delete result.customer_status_text;
        delete result.is_migrated;
        delete result.latest_car_lead;
        delete result.lead_count;
        delete result.lead_open_due_date;
        delete result.lead_open_due_date_format;
        delete result.lead_open_task_coun;
        delete result.password;
        delete result.session_id;
        delete result.updated_by;
        delete result.whatsapp_number;

        result.policy_start_date = moment().add(1, 'day').format('YYYY-MM-DD').toString();
        result.current_policy_active = 1;
        result.existing_policy_expired = 2;
        result.vehicle_type = 1;


        if(payload.vehicle_type == 2)
        {
          
          result.bike_year = (result.bike_year != undefined) ? result.bike_year :new Date().getFullYear();
          result.engine_capacity = (result.engine_capacity != undefined) ? result.engine_capacity : -1;
          result.policy_start_date = moment().add(1, 'day').format('YYYY-MM-DD').toString();
          // result.policy_start_date_month = new Date().getMonth() + 1;
          // result.policy_start_date_day = new Date().getDate();
          // result.policy_start_date_year = new Date().getFullYear();
          result.dob = customer_details.latest_bike_lead.lead_driver_detail.dob;
          // result.dob_month = (result.dob_month != undefined) ? result.dob_month : null;
          // result.dob_day = (result.dob_day != undefined) ? result.dob_day : null;
          // result.dob_year = (result.dob_year != undefined) ? result.dob_year : null;
          result.vehicle_type = 2;

          context.dispatch(ManufaturersActionTypes.GET_ENGINE_CAPCITY, {year: result.bike_year, model_id:701})

          context.commit(VehicleMutationTypes.SET_BIKE, {...result});
        }
        else
        {
            // aded first registration date
            const year = moment().year(result.car_year)
            result.first_registration_date = year.format('YYYY-MM-DD')
            // added missing fields
            result.gcc_specification = 1

           context.dispatch(ManufaturersActionTypes.GET_MANUFACTURERS, {year: result.car_year})
            context.commit(VehicleMutationTypes.SET_VEHICLE, result);
        }
        if(result.manufacturer_id > 0) 
          context.dispatch(ModelsActionTypes.GET_MODELS, {year: result.car_year, manufacturer_id: result.manufacturer_id})
        
       

       

        if(payload && !payload.cid) {
          window.localStorage.removeItem('driver_details');
        }
        window.localStorage.removeItem('qoutes');


        const storecustomer_detatils = window.localStorage.getItem('customer_detatils');
        // console.log('storecustomer_detatils',storecustomer_detatils)
        const customer_detatils = storecustomer_detatils != undefined ? JSON.parse(storecustomer_detatils) : null;

        if(customer_details) {
          window.localStorage.setItem('customer_detatils', JSON.stringify(customer_details))
          context.commit(VehicleMutationTypes.SET_VEHICLE, customer_details);
        }

        if(customer_detatils) {
          result.registration_emirate = 7;
          result.personal_use = 1
          result.manufacturer_id = customer_detatils.manufacturer_id;
          result.car_year = customer_detatils.car_year;
          result.model_id = customer_detatils.model_id;
          result.trim_level_id = customer_detatils.trim_level_id;
          result.car_values = customer_detatils.car_values;
          result.email = customer_detatils.email;

          // const month_dob = customer_detatils.dob_month.toString().length == 1 ? `0${customer_details.dob_month}` : customer_details.dob_month
          // const day_dob = customer_detatils.dob_day.toString().length == 1 ? `0${customer_details.dob_day}` : customer_details.dob_day
          // result.dob = customer_detatils.dob;
          // result.dob_month = month_dob;
          // result.dob_day = day_dob;
          // result.dob_year = customer_detatils.dob_year;
          if(payload.vehicle_type == 2)
          {
            // const todayMonth = new Date().getMonth() + 1;
            // const todayDate = new Date().getDate();
            // const todayYear = new Date().getFullYear();

            result.nationality = customer_detatils.nationality;
            result.bike_year =  customer_detatils.bike_year;
            result.engine_capacity = customer_detatils.engine_capacity;
            result.policy_start_date = customer_detatils.policy_start_date || moment().add(1, 'day').format('YYYY-MM-DD').toString();
            // result.policy_start_date_month = customer_detatils.policy_start_date_month;
            // result.policy_start_date_day = customer_detatils.policy_start_date_day;
            // result.policy_start_date_year = customer_detatils.policy_start_date_year;
            
            context.dispatch(ManufaturersActionTypes.GET_ENGINE_CAPCITY, {year: result.bike_year, model_id:701})
            context.commit(VehicleMutationTypes.SET_BIKE, result);

          }
          else
          {
             context.dispatch(ManufaturersActionTypes.GET_MANUFACTURERS, {year: result.car_year})
            context.commit(VehicleMutationTypes.SET_VEHICLE, result);
          }
          
        }

        
        
        return data.data.customer;
      }

      if(payload && payload.cid) {
        window.localStorage.setItem('driver_details', JSON.stringify(driver_details))
        // window.localStorage.setItem('renewal', '1')
        // console.log(JSON.stringify(driver_details))
      }

      if(customer_details) {
      
        //  window.localStorage.removeItem('customer_detatils');
        if(payload && !payload.cid) {
         window.localStorage.removeItem('driver_details');
        }
         window.localStorage.removeItem('fall');
         window.localStorage.removeItem('fall_back');
         window.localStorage.removeItem('fall_back_dd');
        window.localStorage.setItem('customer_detatils', JSON.stringify(customer_details))
        context.commit(VehicleMutationTypes.SET_VEHICLE, customer_details);
        return customer_details;
      }

    } catch(err) {
      // context.dispatch(VehicleActionTypes.GET_VEHICLE);
      return err
    }
  },

  async [VehicleActionTypes.POST_BIKE_LEAD](context, payload: any) {
    const source = window.localStorage.getItem('sources')
    const renewal = window.localStorage.getItem('renewal')
    payload.preferred_language = window.localStorage.getItem('language')
    if(renewal === '1')
    {
       // The lead is renewal lead
       payload.renewal = 1;
       window.localStorage.removeItem('renewal') // to avoid logical issues in sessions
    }
    context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, true);
    try {
      const data = await ApiService.post(LinkTypes.POST_BIKE_LEAD, payload);
      const uuid = data.data.data.data.uuid.toString().replace('null', '')
      const cuuid = data.data.data.data.cuuid.toString().replace('null', '')
      console.log(">>>>>>>>>>>> cuuid");
      console.log(cuuid);
      
      const result = await Qoute.sendBikeCheapestQuotes({
        uuid:uuid,
        lang: window.localStorage.getItem('language')
      });
      /*
      if(context.rootState.drivers.driver != undefined) {
        context.rootState.drivers.driver.uuid = uuid
        context.rootState.drivers.driver.car_value = payload.car_values
        context.rootState.drivers.driver.is_new = payload.is_new
      }*/
      window.localStorage.setItem('fall_back', uuid);
      const fall = window.localStorage.getItem('fall')
      console.log('BikeDetails')
      payload.customer_source_url = payload.customer_source_url.replace(fall, cuuid)
      window.localStorage.setItem('fall', cuuid);
      //payload.customer_source_url = "/bike-details";
      window.localStorage.setItem('customer_detatils', JSON.stringify(payload));
      context.commit(VehicleMutationTypes.SET_VEHICLE_LOADING_PAGE, false);
      // const host = (process.env.VUE_APP_FOLDER !== '') ? `${process.env.VUE_APP_BIKE_LINK}/${process.env.VUE_APP_FOLDER}` : process.env.VUE_APP_BIKE_LINK;
      // let link = `/${host}/bike-result-listing?uuid=${uuid}`;
      // let link = (process.env.VUE_APP_FOLDER !== '') ? `${process.env.VUE_APP_BIKE_LINK}/bike-result-listing?uuid=${uuid}` : `/${process.env.VUE_APP_FOLDER}/bike-result-listing?uuid=${uuid}`;
      let link = `${process.env.VUE_APP_BIKE_LINK}bike-result-listing?uuid=${uuid}`;
      if(data.status === 200) {
        if(source) link = `${link}${source}`
        if(data.data.data.data.go_back_wizard) {
          const customer = await JSON.parse(window.localStorage.getItem('customer_detatils') || '')
          customer.sid = await data.data.data.data.sid
          window.localStorage.setItem('customer_detatils', JSON.stringify(customer))
          link = `${link}&sid=${data.data.data.data.sid}`
        }

        window.location.href = link
      }
      return data
    } catch (err) {
      return err;
    }
  },
};
